<template>
  <v-layout width="100%">
    <v-app-bar app height="124" class="align-start elevation-1">
      <v-col cols="12" class="py-0">
        <v-row dense align="center">
          <v-col cols="auto" class="d-flex d-lg-none pa-0">
            <!--Platzhalter für mobile Menu-->
            <div style="width: 25px"></div>
          </v-col>
          <v-col cols="auto">
            <v-btn icon x-large @click="$router.go(-1)">
              <v-icon x-large>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-toolbar-title>
              <span>Termin</span>
              <span v-if="dataset.ist_abgesagt"> (abgesagt)</span>
            </v-toolbar-title>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn color="orange lighten-2" :to="linkEditDataset">
              <v-icon class="grey--text text--lighten-4">mdi-pencil</v-icon>
              <span class="ml-1 grey--text text--lighten-4 d-none d-lg-flex">
                Bearbeiten
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-app-bar>
    <v-container fluid class="px-0" v-if="dataset.Termin_ID">
      <v-col cols="11" xl="8" lg="9" md="11" class="mx-auto px-0">
        <!--Termindaten und Kundendaten-->
        <v-row dense class="align-center justify-center pb-5">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-title class="px-2">
                <v-row dense align="center">
                  <v-col cols="12">
                    <v-toolbar-title>
                      <v-row dense align="center">
                        <v-col cols="auto">
                          <span>
                            {{ dataset.Terminabteilung }} -
                            {{ dataset.Terminkategorie }}
                          </span>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto" v-if="datasetNotes.length > 0">
                          <v-menu offset-y :nudge-left="266" :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn outlined v-bind="attrs" v-on="on">
                                <v-icon>mdi-alert-box-outline</v-icon>
                              </v-btn>
                            </template>
                            <v-card width="330">
                              <v-card-title><span>Notizen:</span> </v-card-title>
                              <v-divider></v-divider>
                              <v-card-text class="text--primary">
                                <v-row dense align="center" justify="center">
                                  <v-col cols="12" v-for="item in datasetNotes" :key="item.Terminnotiz_ID">
                                    <v-card tile>
                                      <v-card-text class="text--primary">
                                        <v-row dense align="center">
                                          <v-col cols="auto">
                                            <span>
                                              {{ item.Notiz }}
                                            </span>
                                          </v-col>
                                        </v-row>
                                      </v-card-text>
                                      <v-divider></v-divider>
                                      <v-card-actions>
                                        <v-row dense align="center">
                                          <v-col cols="auto">
                                            <span class="text-caption">
                                              {{ new Date(item.notiert_am).toLocaleDateString("de-DE") }}
                                            </span>
                                          </v-col>
                                        </v-row>
                                      </v-card-actions>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider inset></v-divider>
              <v-card-text class="px-2 subtitle-2">
                <v-row dense align="center">
                  <v-spacer></v-spacer>
                  <v-col cols="12" xl="4" lg="4" md="4">
                    <v-row dense>
                      <v-col cols="auto" class="mx-auto font-weight-bold">
                        <v-card shaped width="225">
                          <v-card-title :class="dateColor" style="height:64px;">
                            <v-row dense align="center" class="">
                              <v-col cols="auto">
                                <span>{{ getDateMonthName(dataset.Datum) }}</span>
                              </v-col>
                              <v-spacer></v-spacer>
                              <v-col cols="auto">
                                <span>{{ new Date(dataset.Datum).getFullYear() }}</span>
                              </v-col>
                            </v-row>
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-card-text style="height:150px;">
                            <v-row dense align="center" justify="center">
                              <v-col cols="auto"
                                ><span class="text--primary text-h1">{{
                                  new Date(dataset.Datum).toLocaleDateString("de-DE", {
                                    day: "2-digit",
                                  }) + "."
                                }}</span></v-col
                              >
                            </v-row>
                            <v-row dense align="center" justify="center">
                              <v-col cols="auto"
                                ><span class="text--primary text-h5">{{
                                  new Date(dataset.Datum).toLocaleDateString("de-DE", {
                                    weekday: "long",
                                  })
                                }}</span></v-col
                              >
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col class="pt-3" cols="12" xl="7" lg="7" md="7">
                    <v-row dense>
                      <v-col cols="5" md="6" sm="6" class="font-weight-bold">
                        Kundenname
                      </v-col>
                      <v-col cols="6">
                        <span v-if="dataset.Kundenname">{{ dataset.Kundenname }}</span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="5" md="6" sm="6" class="font-weight-bold">
                        Telefon
                      </v-col>

                      <v-col cols="6">
                        <span v-if="dataset.Telefon">
                          {{ dataset.Telefon }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="5" md="6" sm="6" class="font-weight-bold">
                        Email
                      </v-col>
                      <v-col cols="6">
                        <span class="text-no-wrap" v-if="dataset.Email">
                          {{ dataset.Email }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-card>
        </v-row>
        <v-row dense class="align-center justify-center">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-title class="px-2">
                <v-row dense>
                  <v-col cols="12">
                    <v-toolbar-title>
                      Notizen
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider inset></v-divider>
              <v-card-text class="px-2 subtitle-2">
                <v-row dense align="start">
                  <v-col cols="12">
                    <v-card tile v-for="item in datasetNotes" :key="item.Terminnotiz_ID">
                      <v-card-text class="text--primary">
                        <v-row dense align="center">
                          <v-col cols="auto">
                            <span>
                              {{ item.Notiz }}
                            </span>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-row dense align="center">
                          <v-col cols="auto">
                            <span class="text-caption">
                              {{
                                new Date(item.notiert_am).toLocaleDateString("de-DE", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })
                              }}
                            </span>
                          </v-col>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-card>
        </v-row>
        <!--Terminartikel und Terminaccessoires-->
        <v-row dense class="align-center justify-center pt-5">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-title class="px-2">
                <v-row dense>
                  <v-col cols="12">
                    <v-toolbar-title>
                      Artikel
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider inset></v-divider>
              <v-card-text class="px-2 subtitle-2">
                <v-row dense align="start">
                  <v-col cols="12">
                    <v-expansion-panels mandatory>
                      <v-expansion-panel v-for="item in datasetArticles" :key="item.Artikel_ID">
                        <v-expansion-panel-header>
                          <template v-slot:default="{ open }">
                            <v-row dense align="center">
                              <v-col cols="6">
                                <span class="font-weight-bold text-no-wrap text-h5">
                                  {{ item.Artikelnummer }}
                                </span>
                              </v-col>
                              <v-col cols="auto">
                                <v-btn icon outlined :to="linkShowArticle(item.Artikel_ID)"><v-icon>mdi-tshirt-crew-outline</v-icon></v-btn>
                              </v-col>
                              <v-spacer></v-spacer>
                              <v-col cols="4" class="text--secondary">
                                <span v-if="open" key="0" class="text--primary"
                                  ><span class="font-weight-bold"> {{ item.Aktion }}: </span>
                                  <span class="text-no-wrap">
                                    {{ item.Preis + " €" }}
                                  </span>
                                </span>
                                <span v-else key="1" class="text--primary">
                                  <span class="font-weight-bold"> {{ item.Aktion }}: </span>
                                  <span class="text-no-wrap">
                                    {{ item.Preis + " €" }}
                                  </span>
                                </span>
                              </v-col>
                              <v-spacer></v-spacer>
                            </v-row>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-col cols="12">
                            <v-row dense>
                              <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                                Artikelname
                              </v-col>
                              <v-col cols="7" xl="9" lg="9" md="9" sm="6">
                                <span v-if="item.Artikelname">
                                  {{ item.Artikelname }}
                                </span>
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                                Kategorie
                              </v-col>
                              <v-col cols="7" xl="9" lg="9" md="9" sm="6">
                                <span v-if="item.Abteilung">
                                  {{ item.Abteilung }}
                                </span>
                                <span v-if="item.Artikelgruppe">
                                  {{ " / " + item.Artikelgruppe }}
                                </span>
                                <span v-if="item.Artikeluntergruppe">
                                  {{ " / " + item.Artikeluntergruppe }}
                                </span>
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                                Größe
                              </v-col>
                              <v-col cols="7" xl="9" lg="9" md="9" sm="6">
                                <span v-if="item.Größe">
                                  {{ item.Größe }}
                                </span>
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                                Farbe(n)
                              </v-col>
                              <v-col cols="7" xl="9" lg="9" md="9" sm="6">
                                <span v-if="item.Farben">
                                  {{ item.Farben }}
                                </span>
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                                Stoff(e)
                              </v-col>
                              <v-col cols="7" xl="9" lg="9" md="9" sm="6">
                                <span v-if="item.Stoffe">
                                  {{ item.Stoffe }}
                                </span>
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                                Schnitt
                              </v-col>
                              <v-col cols="7" xl="9" lg="9" md="9" sm="6">
                                <span v-if="item.Schnitt">
                                  {{ item.Schnitt }}
                                </span>
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                                Besonderheiten
                              </v-col>
                              <v-col cols="7" sm="6">
                                <span v-if="item.Besonderheiten">
                                  {{ item.Besonderheiten }}
                                </span>
                              </v-col>
                            </v-row>
                            <v-row dense align="center">
                              <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                                Hersteller
                              </v-col>
                              <v-col cols="5" v-if="item.Hersteller">
                                {{ item.Hersteller }}
                              </v-col>
                              <v-col cols="1" v-if="item.Hersteller">
                                <v-btn icon outlined :to="linkShowManufacturer(item.Hersteller_ID)"><v-icon>mdi-information-variant</v-icon></v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-title class="px-2" v-if="datasetAccessoires.length > 0">
                <v-row dense>
                  <v-col cols="12">
                    <v-toolbar-title>
                      Accessoires
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider inset v-if="datasetAccessoires.length > 0"></v-divider>
              <v-card-text class="px-2 subtitle-2" v-if="datasetAccessoires.length > 0">
                <v-row dense align="start">
                  <v-col cols="12">
                    <v-expansion-panels>
                      <v-expansion-panel v-for="item in datasetAccessoires" :key="item.Artikel_ID">
                        <v-expansion-panel-header>
                          <template v-slot:default="{ open }">
                            <v-row dense align="center">
                              <v-col cols="6">
                                <span class="font-weight-bold text-no-wrap text-h5">
                                  {{ item.Artikelnummer }}
                                </span>
                              </v-col>
                              <v-col cols="auto">
                                <v-btn icon outlined :to="linkShowArticle(item.Artikel_ID)"><v-icon>mdi-tshirt-crew-outline</v-icon></v-btn>
                              </v-col>
                              <v-spacer></v-spacer>
                              <v-col cols="4" class="text--secondary">
                                <span v-if="open" key="0" class="text--primary"
                                  ><span class="font-weight-bold"> {{ item.Aktion }}: </span>
                                  <span class="text-no-wrap">
                                    {{ item.Preis + " €" }}
                                  </span>
                                </span>
                                <span v-else key="1" class="text--primary">
                                  <span class="font-weight-bold"> {{ item.Aktion }}: </span>
                                  <span class="text-no-wrap">
                                    {{ item.Preis + " €" }}
                                  </span>
                                </span>
                              </v-col>
                              <v-spacer></v-spacer>
                            </v-row>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-col cols="12">
                            <v-row dense>
                              <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                                Artikelname
                              </v-col>
                              <v-col cols="7" xl="9" lg="9" md="9" sm="6">
                                <span v-if="item.Artikelname">
                                  {{ item.Artikelname }}
                                </span>
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                                Kategorie
                              </v-col>
                              <v-col cols="7" xl="9" lg="9" md="9" sm="6">
                                <span v-if="item.Abteilung">
                                  {{ item.Abteilung }}
                                </span>
                                <span v-if="item.Artikelgruppe">
                                  {{ " / " + item.Artikelgruppe }}
                                </span>
                                <span v-if="item.Artikeluntergruppe">
                                  {{ " / " + item.Artikeluntergruppe }}
                                </span>
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                                Größe
                              </v-col>
                              <v-col cols="7" xl="9" lg="9" md="9" sm="6">
                                <span v-if="item.Größe">
                                  {{ item.Größe }}
                                </span>
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                                Farbe(n)
                              </v-col>
                              <v-col cols="7" xl="9" lg="9" md="9" sm="6">
                                <span v-if="item.Farben">
                                  {{ item.Farben }}
                                </span>
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                                Stoff(e)
                              </v-col>
                              <v-col cols="7" xl="9" lg="9" md="9" sm="6">
                                <span v-if="item.Stoffe">
                                  {{ item.Stoffe }}
                                </span>
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                                Schnitt
                              </v-col>
                              <v-col cols="7" xl="9" lg="9" md="9" sm="6">
                                <span v-if="item.Schnitt">
                                  {{ item.Schnitt }}
                                </span>
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                                Besonderheiten
                              </v-col>
                              <v-col cols="7" sm="6">
                                <span v-if="item.Besonderheiten">
                                  {{ item.Besonderheiten }}
                                </span>
                              </v-col>
                            </v-row>
                            <v-row dense align="center">
                              <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                                Hersteller
                              </v-col>
                              <v-col cols="5" v-if="item.Hersteller">
                                {{ item.Hersteller }}
                              </v-col>
                              <v-col cols="1" v-if="item.Hersteller">
                                <v-btn icon outlined :to="linkShowManufacturer(item.Hersteller_ID)"><v-icon>mdi-information-variant</v-icon></v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-card>
        </v-row>
        <!--Terminvorgänge-->
        <v-row dense class="align-center justify-center pt-5">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-title class="px-2">
                <v-row dense>
                  <v-col cols="12">
                    <v-toolbar-title>
                      Vorgänge
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider inset></v-divider>
              <v-card-text class="px-2 subtitle-2">
                <v-row dense>
                  <v-col cols="12">
                    <v-data-table
                      height="calc(48px + (48px * 3))"
                      fixed-header
                      hide-default-footer
                      :items="datasetEvents"
                      item-key="Artikelvorgang_ID"
                      :items-per-page="3"
                      :page.sync="page"
                      @page-count="pageCount = $event"
                      :headers="datasetEventsHeaders"
                      mobile-breakpoint="300"
                      style="width: 100%;"
                    >
                      <template v-slot:[`item.Vorgang`]="{ item }">
                        <span class="font-weight-bold"> {{ item.Vorgang }}</span>
                      </template>
                      <template v-slot:[`item.Datum`]="{ item }">
                        <span>{{
                          new Date(item.Datum).toLocaleDateString("de-DE", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                          }) + " Uhr"
                        }}</span>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider inset></v-divider>
              <v-card-actions>
                <v-row dense align="center">
                  <v-col cols="12">
                    <v-pagination color="grey darken-2" v-model="page" :length="pageCount"></v-pagination>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-card>
        </v-row>
      </v-col>
    </v-container>
    <v-container fluid class="px-0" v-else>
      <v-col cols="11" xl="8" lg="9" md="11" class="mx-auto px-0">
        <!--Termindaten und Kundendaten-->
        <v-row dense class="align-center justify-center pb-5">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-title class="px-2">
                <v-row dense align="center">
                  <v-col cols="12">
                    <v-toolbar-title>
                      <v-row dense align="center">
                        <v-col cols="auto">
                          <span>
                            Gelöschter Termin
                          </span>
                        </v-col>
                      </v-row>
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider inset></v-divider>
              <v-card-text class="subtitle-2">
                <v-row dense align="center">
                  <v-col cols="12">
                    <span class="font-weight-bold">Hinweis:</span>
                    <span>Der Artikel muss vor dem Löschen eines Termins von dem Termin gelöst werden. Er wurde also</span>
                    <span class="text-decoration-underline">weder verkauft noch verliehen! </span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-card>
        </v-row>
      </v-col>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  created() {
    this.initialize();
  },

  mounted() {
    this.initialize();
  },

  data: () => ({
    dataset: {},
    datasetArticles: [],
    amountArticles: [],
    datasetAccessoires: [],
    amountAccessoires: [],
    datasetNotes: [],
    amountNotes: [],
    hiddenColumns: ["Terminvorgang_ID"],
    datasetEventsHeaders: [],
    datasetEvents: [],
    page: 1,
    pageCount: 0,
  }),

  computed: {
    checkCurrentUser() {
      const user = localStorage.getItem("user");

      if (user === "Aline" || user === "andre") {
        return true;
      } else return false;
    },

    linkEditDataset() {
      if (this.$route.params.id > 0) {
        return `/wws/${this.$route.meta.request}/dataset/edit/${this.$route.params.id}`;
      }
    },

    dateColor() {
      if (this.dataset.Terminkategorie_ID === 1) {
        return "Leih";
      } else if (this.dataset.Terminkategorie_ID === 2) {
        return "Kauf";
      } else if (this.dataset.Terminkategorie_ID === 3) {
        return "Bestand";
      } else return "";
    },
  },

  methods: {
    async initialize() {
      const response = await fetch(`/api/${this.$route.meta.request}/dataset/show/${this.$route.params.id}`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      const alignmentHeaders = ["start", "end", "center"];
      const dividerHeaders = [true, true, false];

      this.datasetEventsHeaders = Object.keys(this.datasetEvents[0])
        .filter((it) => this.hiddenColumns.indexOf(it) === -1)
        .map((it, i) => {
          return {
            text: it,
            value: it,
            sortable: false,
            filterable: false,
            align: alignmentHeaders[i],
            divider: dividerHeaders[i],
            class: "text--primary text-uppercase",
          };
        });
    },

    getDateMonthName(date) {
      const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

      const d = new Date(date);
      return monthNames[d.getMonth()];
    },

    linkShowManufacturer(manufacturer) {
      if (manufacturer) {
        return `/wws/database/manufacturers/dataset/show/${manufacturer}`;
      }
    },

    linkShowArticle(id) {
      if (id) {
        return `/wws/database/articles/dataset/show/${id}`;
      }
    },
  },
};
</script>

<style>
.Leih {
  background: #53ff5c !important;
}

.Kauf {
  background: #fcff51 !important;
}

.Bestand {
  background: #d52bff !important;
}
</style>
